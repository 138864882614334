export enum ViewSection {
  REVIEWS = 'Reviews',
  OFFERS = 'Offers',
  INTERVIEWS = 'Interviews'
}

// This is the enum for ViewSelector, which is kind of confusing.
// We should rename ViewSelection once I know what it was supposed to do
export enum DisplayType {
  GRID = 1,
  LIST = 2
}

export enum StarValue {
  EMPTY = '0',
  HALF = '0.5',
  FULL = '1'
}
