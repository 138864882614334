import './companyListItem.scss';

import { Company } from '@csh/ui/api/types/company';
import { Link } from 'react-router-dom';
import { Media } from 'reactstrap';
import React from 'react';

interface CompanyListItemProps {
  company: Company;
}

export const CompanyListItem: React.FunctionComponent<CompanyListItemProps> = ({
  company
}) => {
  const imgStyle = {
    maxWidth: 48,
    maxHeight: 48,
    height: 48,
    width: 48
  };

  return (
    <Link
      to={`/company/${company.id}`}
      style={{ color: 'inherit', textDecoration: 'inherit' }}
    >
      <div className={'company-wrapper'}>
        <Media
          bottom
          object
          src={
            'https://user-images.githubusercontent.com/194400/49531010-48dad180-f8b1-11e8-8d89-1e61320e1d82.png'
          }
          style={imgStyle}
        />
        <h4 className={'companyInfo'}>{company.name}</h4>
        <h5>
          {company.JobReviews ? company.JobReviews.length : 0} Review
          {company.JobReviews?.length == 1 ? '' : 's'}
        </h5>
        <h5>
          {company.Interviews ? company.Interviews.length : 0} Interview
          {company.Interviews?.length == 1 ? '' : 's'}
        </h5>
        <h5>
          {company.Offers ? company.Offers.length : 0} Offer
          {company.Offers?.length == 1 ? '' : 's'}
        </h5>
      </div>
    </Link>
  );
};
