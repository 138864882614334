import { Button, ButtonGroup } from 'reactstrap';

import { DisplayType } from '../enums';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

interface ViewSelectorProps {
  selectedView: DisplayType;
  setSelectedView: (view: DisplayType) => void;
}

export const ViewSelector: React.FunctionComponent<ViewSelectorProps> = ({
  selectedView,
  setSelectedView
}) => {
  return (
    <ButtonGroup>
      <Button
        outline
        color="secondary"
        size={'sm'}
        onClick={() => setSelectedView(DisplayType.GRID)}
        active={selectedView === DisplayType.GRID}
      >
        <FontAwesomeIcon icon={'th-large'} />
      </Button>
      <Button
        outline
        color="secondary"
        size={'sm'}
        onClick={() => setSelectedView(DisplayType.LIST)}
        active={selectedView === 2}
      >
        <FontAwesomeIcon icon={'bars'} />
      </Button>
    </ButtonGroup>
  );
};
